const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_VIDEO, RUMBLE_COMMENT, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, FEDIVERSE, BLUESKY } = require('@/constants/sites')
const { LINKS, TIMELINE, ACTIVITY, SEARCH } = require('@/constants/tools')

let apiBaseUrl = 'https://api.smat-app.com'
if (process.env.VUE_APP_API) { // e.g. http://127.0.0.1:5000
  apiBaseUrl = process.env.VUE_APP_API
  console.info('VUE_APP_API:', apiBaseUrl)
}

export function queries (settings, page) {
  return settings.websites
    .filter((website) => website.active)
    .map((website) => {
      const { name: site } = website
      const {
        searchTerm: term,
        startDate: since,
        endDate: until,
        interval,
      } = settings
      const url = new URL(apiBaseUrl)

      switch (page) {
        case LINKS:
          url.pathname = 'content'
          url.search = new URLSearchParams({
            site, term, since, until, limit: 10000
          })
          url.searchParams.append('querytype', settings.esquery)
          break

        case TIMELINE:
          url.pathname = 'timeseries'
          url.search = new URLSearchParams({
            site, term, since, until, interval
          })
          url.searchParams.append('querytype', settings.esquery)
          if (settings.changepoint) url.searchParams.append('changepoint', 'True')
          break

        case ACTIVITY:
          url.pathname = 'activity'
          url.search = new URLSearchParams({
            site, term, since, until, agg_by: chooseAggBy(website, settings)
          })
          url.searchParams.append('querytype', settings.esquery)
          break

        case SEARCH:
          url.pathname = 'content'
          url.search = new URLSearchParams({
            site, term, since, until, limit: 50, agg_by: chooseAggBy(website, settings)
          })
          if (settings.esquery) url.searchParams.append('esquery', 'True')
          break

        default:
          throw new Error(`Invalid page: ${page}`)
      }

      return {
        ...website,
        url: url.href
      }
    })
}

function chooseAggBy (website, settings) {
  let aggBy = 'author'
  if (website.name === TWITTER) { aggBy = 'screen_name' }
  if (website.name === GAB) { aggBy = 'account.acct' }
  if (website.name === FOURCHAN) { aggBy = 'name' }
  if (website.name === EIGHTKUN) { aggBy = 'name' }
  if (website.name === PARLER) { aggBy = 'username' }
  if (website.name === WIN) { aggBy = 'author' }
  if (website.name === POAL) { aggBy = 'user' }
  if (website.name === TELEGRAM) { aggBy = 'userinfo.username' }
  if (website.name === GETTR) { aggBy = 'uid' }
  if (website.name === BITCHUTE_VIDEO) { aggBy = 'creator' }
  if (website.name === BITCHUTE_COMMENT) { aggBy = 'fullname' }
  if (website.name === TIKTOK_VIDEO) { aggBy = 'author' }
  if (website.name === TIKTOK_COMMENT) { aggBy = 'author' }
  if (website.name === MEWE) { aggBy = 'userid' }
  if (website.name === WIMKIN) { aggBy = 'author' }
  if (website.name === RUMBLE_VIDEO) { aggBy = 'username' }
  if (website.name === RUMBLE_COMMENT) { aggBy = 'username' }
  if (website.name === MINDS) { aggBy = 'user.username' }
  if (website.name === LBRY_VIDEO) { aggBy = 'signing_channel.name' }
  if (website.name === LBRY_COMMENT) { aggBy = 'channel_name' }
  if (website.name === VK) { aggBy = 'author' }
  if (website.name === TRUTH_SOCIAL) { aggBy = 'account.acct' }
  if (website.name === RUTUBE_VIDEO) { aggBy = 'author.name' }
  if (website.name === RUTUBE_COMMENT) { aggBy = 'user.name' }
  if (website.name === OK) { aggBy = 'author' }
  if (website.name === BLUESKY) { aggBy = 'authorProfile.handle' }
  if (website.name === FEDIVERSE) { aggBy = 'account.acct' }
  return aggBy
}
