<template>
  <div>
    <div v-if="resultsAvailable">
      <CardChartTimeline
        v-for="website in resultsData"
        :key="website.label"
        :lastSearchSettings="lastSearchSettings"
        :website="website"
      />

      <TableTimeline :resultsData="resultsData" />
    </div>
    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import { get } from 'lodash'
import moment from 'moment'
import CardChartTimeline from '@/components/charts/CardChartTimeline'
import TableTimeline from '@/components/tables/TableTimeline'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'

export default {
  components: {
    CardChartTimeline,
    TableTimeline,
    BodyLoading,
    BodyError
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    resultsData () {
      if (this.results !== null) {
        return this.results.map((websiteResult) => {
          const createdKey = websiteResult.data.created_key

          const changepointData = get(
            websiteResult.data,
            `aggregations.changepoint.${createdKey}`,
            []
          )

          const normalData = get(
            websiteResult.data,
            `aggregations.${createdKey}.buckets`,
            []
          )

          // if the actual data in the db starts or ends with a series of '0' `doc_count`s, then the api doesn't return anything there. we still want to display the lack of data for the whole range we searched for, so we manually add it back here
          const addMissing = (data, append = false) => {
            // initially the search settings aren't set, in that case we can't do anything so return without processing
            if (!this.lastSearchSettings.endDate) return data

            const newItems = []

            let prevDate = moment.utc(append
              ? data[data.length - 1].key
              : data[0].key).clone()

            const finishDate = moment.utc(append
              ? this.lastSearchSettings.endDate
              : this.lastSearchSettings.startDate)

            // the api for some reason doesn't return the data for the selected end date, so to match that behaviour and not give incorrect data we just stop filling in data 1 day early
            if (append) finishDate.subtract(1, 'day')

            while ((append
              ? prevDate.isBefore(finishDate)
              : prevDate.isAfter(finishDate)) && newItems.length < 1000) {
              const newItem = {
                key: append
                  ? prevDate.add(1, this.lastSearchSettings.interval)
                  : prevDate.subtract(1, this.lastSearchSettings.interval),
                doc_count: 0,
              }
              if (append) {
                newItems.push(newItem)
              } else {
                newItems.unshift(newItem)
              }
              prevDate = newItem.key.clone()
            }
            return append ? [...data, ...newItems] : [...newItems, ...data]
          }

          const dataToPoint = (t) => ({
            date: moment.utc(t.key).format('YYYY-MM-DD HH:mm:ss'),
            usage: t.doc_count
          })

          return this.lastSearchSettings.changepoint
            ? {
                label: websiteResult.label,
                before: addMissing(changepointData.before).map(dataToPoint),
                after: addMissing(changepointData.after, true).map(dataToPoint),
                name: websiteResult.name
              }
            : {
                label: websiteResult.label,
                data: addMissing(addMissing(normalData), true).map(dataToPoint),
                name: websiteResult.name
              }
        })
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.card) {
  margin-top: var(--spacing-4);
}
</style>
