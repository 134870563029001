import om from '@/assets/Open-Measures-Stacked-Black.svg'

export function downloadBase64 (base64) {
  const a = document.createElement('a')
  a.href = base64
  a.download = 'open-measures-chart.png'
  a.click()
}

export const chartPlugins = () => {
  const image = new Image()
  image.src = om

  return [
    {
      id: 'customCanvasBackgroundImage',
      beforeDatasetsDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx
          const { top, left, height, width } = chart.chartArea
          const imgWidthRatio = 2
          // numbers are the size the svg renders in an image viewer
          // NOTE: remember to update this if you change the svg
          const imgAspectRatio = 1437 / 329
          const imgWidth = width / imgWidthRatio
          const imgHeight = imgWidth / imgAspectRatio
          const x = left + (width / 2) - (imgWidth / 2)
          const y = top + (height / 2) - (imgHeight / 2)
          ctx.globalAlpha = 0.15
          ctx.drawImage(image, x, y, imgWidth, imgHeight)
          ctx.globalAlpha = 1
        } else {
          image.onload = () => chart.draw()
        }
      }
    }
  ]
}